<template>
  <v-app class="page-einsearch">
    <tev-navbar title="Sunbiz Search" />
    <v-content>
      <v-layout row wrap pt-4>
        <v-flex xs12 sm12 class="mb-5">
          <v-container>
            <transition name="fade">
              <v-layout row wrap v-if="show">
                <v-flex xs12 sm8 offset-sm2 class="mb-5">

                  <v-layout wrap align-center justify-space-between row>
                    <h4 class="ma-0 d-flex">EIN Search</h4>
                    <v-btn flat small @click="query=261637482;newSearch()">ex. 26-1637482</v-btn>
                  </v-layout>

                  <v-flex xs12>
                    <form v-on:submit.prevent="newSearch">
                      <v-text-field v-model="query" label="Search for a EIN number..." append-icon="search" @click:append="newSearch" :autofocus="$isDesktop()" single-line box></v-text-field>
                    </form>
                  </v-flex>
                  <div class="text-xs-center py-4" v-if="isLoading">
                    <v-progress-circular :size="50" color="primary" indeterminate/>
                  </div>

                  <v-card v-if="results&&!isLoading">
                    <v-card-title primary-title>
                      <div class="grey--text">
                        <h3 class="headline mb-0 black--text">{{ results.company_name }}</h3>
                        <div>EIN Number: {{ results.ein_number }}</div>
                        <div>Status: <strong v-bind:class="{'green--text': results.status=='ACTIVE'}">{{ results.status }}</strong></div>
                      </div>
                      <v-flex xs12>
                        <v-btn flat block outline mb-2 color="primary" :href="item.url" target="_blank" v-for="item in results.documents" v-bind:key="item.name">{{item.name}}</v-btn>
                      </v-flex>
                    </v-card-title>
                  </v-card>

                </v-flex>
              </v-layout>
            </transition>
          </v-container>
        </v-flex>
      </v-layout>
    </v-content>
  </v-app>
</template>
<script>
import {
  mapGetters
} from 'vuex'
import axios from 'axios'

export default {
  data() {
      return {
        show: false,
        isLoading: false,
        results: null,
        query: null
      }
    },
    computed: {
      ...mapGetters({
        currentUser: 'currentUser'
      })
    },
    created() {
      this.checkCurrentLogin()
    },
    updated() {
      this.checkCurrentLogin()
    },
    methods: {
      checkCurrentLogin() {
        const now = Math.floor(Date.now() / 1000);
        if (!this.currentUser || !this.currentUser.isLoggedIn || !localStorage.token || now >= this.currentUser.expire) {
          this.$router.push('/login?redirect=' + this.$route.path)
        }
        this.currentUser.isCompliance = this.currentUser.auth.includes('ROLE_L_CM')
        this.currentUser.isManager = this.currentUser.auth.includes('ROLE_L_PM')
        this.currentUser.isEditor = this.currentUser.auth.includes('ROLE_PEND_PROP_ED')
      },
      newSearch() {
        const _this = this;
        if(!this.query) {
          return
        }
        this.isLoading = true
        axios.get('https://clientev.geetsi.es/api/ein?query=' + String(this.query).replace(/\D/g,''))
          .then(response => {
            _this.results = response.data || [];
          })
          .catch(e => {
            // eslint-disable-next-line
            console.error(e)
          })
          .finally(() => (this.isLoading = false))
      }
    },
    mounted() {
      if (!this.currentUser.isCompliance) {
        return this.$router.push('/properties')
      }
      this.show = true;
    }
}
</script>
